import { atom } from "recoil";

interface IAppState {
  MenuSelected: string;
}
export const AppState = atom<IAppState>({
  key: "AppState",
  default: {
    MenuSelected: "Users",
  },
});
