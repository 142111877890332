import { atom } from "recoil";

interface IDialogState {
  Msg: string;
  show: boolean;
  DialogType: "Confirm" | "Alert" | "";
  ActtionCancel?: () => void;
  ActtionOK?: () => void;
}
export const DialogState = atom<IDialogState>({
  key: "DialogState",
  default: {
    Msg: "",
    show: false,
    DialogType: "",
  },
});
