import React, { useEffect } from "react";
import { Alert, Grid, Tab, Tabs } from "@mui/material";
import { OrganizationRegister } from "./organizationRegister";
import { IndividualRegister } from "./individualRegister";
import { Country, CountryApi } from "../../../api/src";
const Register = () => {
  const [value, setValue] = React.useState(0);
  const [Countrys, setCountry] = React.useState<Country[]>([]);
  const [ShowMsgSuccess, setShowMsgSuccess] = React.useState<{ msg: string, show: boolean }>({ show: false, msg: "" });
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setShowMsgSuccess({ show: false, msg: "" });
  };
  useEffect(() => {
    new CountryApi().apiCountryGetAllCountrysGet().then((res) => {
      setCountry(res?.data.result || []);
    });
  }, []);
  return (
    <>
      <div
        style={{
          height: "100vh",
          padding: "0 10px",
          display: "flex",
          background:
            "linear-gradient(155deg, rgb(51 108 248) 0%, rgb(125 184 254) 49%, rgb(200 212 255) 77%)",
        }}
      >
        <div
          style={{
            margin: "auto",
            maxWidth: 1000,
          }}
        >
          {/* <div style={{ textAlign: "center", width: "100%" }}>
            <img src="/app.ico"></img>
          </div> */}
          <Grid container
            spacing={2}
          >
            <Grid
              style={{
                background: "#fff",
                borderRadius: 10,
                paddingBottom: 15,
                paddingLeft: 15,
                paddingRight: 15,
              }}
            >
              <Tabs value={value} onChange={handleChange}>
                {/* <Tab label="Company" /> */}
                <Tab label="Personal" />
              </Tabs>
              {ShowMsgSuccess.show && (
                <>
                  <br></br>
                  <Alert
                    onClose={() => {
                      setShowMsgSuccess({ show: false, msg: "" });
                    }}
                    severity="success"
                  >
                    {ShowMsgSuccess.msg}
                  </Alert>
                </>
              )}
              {value === 0 && (
                <OrganizationRegister
                  countrys={Countrys}
                  onSubmit={(data: any) => {
                    if(data.user.licenseType == 'Full' || data.sendMail == false){
                      setShowMsgSuccess({ show: true, msg: `Register success.` });
                    }else{
                      setShowMsgSuccess({ show: true, msg: `Register success, License key we send your email ${data.user.email}.` });
                    }
                  }}
                ></OrganizationRegister>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};
export default Register;
