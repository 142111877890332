import axios from "axios";
import { LocalStorageKey } from "../common/constant/constant";
import * as Api from "../api/src/index";

const configAxios = () => {
  const requestHandler = (request: any) => {
    const userInfoCache = localStorage.getItem(LocalStorageKey.UserInfo);
    let userinfo = null;
    if (userInfoCache) {
      userinfo = JSON.parse(userInfoCache) as Api.AccessToken;
      request.headers["Authorization"] =
        userinfo?.token_type + " " + userinfo?.access_token;
    }
    return request;
  };
  const responseHandler = (response: any) => {
    return response;
  };
  console.log(process.env);
  axios.defaults.baseURL = process.env.REACT_APP_URL_API;
  axios.interceptors.request.use((request) => requestHandler(request));
  axios.interceptors.response.use(
    (response) => responseHandler(response),
    (err) => {
      if (err.response.status === 401) {
        window.location.href = "/login";
      }
      return err;
    }
  );
};

export default configAxios;
