import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import "./App.css";
import Users from "./components/pages/user/users";
import { useEffect } from "react";
import configAxios from "./api/configAxios";
import Companys from "./components/pages/company/companys";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { MsgState } from "./recoil/msgState";
import { useRecoilState } from "recoil";
import { AppState } from "./recoil/appState";
import Layout from "./Layout";
import Register from "./components/pages/register/register";
import LayoutBasic from "./layoutBasic";
import Login from "./components/pages/login/login";
import { AuthenService } from "./services/authenServices";
function App() {
  const { GetUserInfo } = AuthenService();
  useEffect(() => {
    configAxios();
    if (
      !GetUserInfo() &&
      window.location.href.toLocaleLowerCase().indexOf("/login") < 0 &&
      window.location.href.toLocaleLowerCase().indexOf("/register") < 0
    ) {
      window.location.href = "/login";
    }
  }, []);
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="" element={<Navigate to="/users" replace={true} />} />
          <Route path="/" element={<LayoutBasic></LayoutBasic>}>
            <Route path="/login" element={<Login></Login>} />
            <Route path="/register" element={<Register></Register>} />
          </Route>
          <Route path="/" element={<Layout></Layout>}>
            <Route path="/users" element={<Users></Users>} />
            <Route path="/companys" element={<Companys></Companys>} />
          </Route>
          <Route
            path="*"
            element={
              <div style={{textAlign:'center'}}>
                <h2>404 Page not found</h2>
              </div>
            }
          />
          
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
