import { atom } from "recoil";

interface IMsgState {
  Msg: string;
  show: boolean;
}
export const MsgState = atom<IMsgState>({
  key: "MsgState",
  default: {
    Msg: "",
    show: false,
  },
});
