import React from "react";
import { Button, Grid, InputAdornment, Link, TextField } from "@mui/material";
import { AccountCircle, Lock } from "@mui/icons-material";
import { LoginService } from "./service/loginService";
const Login = () => {
  const { formikLogin } = LoginService();
  return (
    <>
      <div
        style={{
          height: "100vh",
          padding: "0 10px",
          display: "flex",
          background:
            "linear-gradient(155deg, rgb(51 108 248) 0%, rgb(125 184 254) 49%, rgb(200 212 255) 77%)",
        }}
      >
        <div
          style={{
            margin: "auto",
            maxWidth: 400,
            background: "#fff",
            borderRadius: 10,
            padding: 20,
          }}
          onKeyDown={(e) => {
            if (e.code && e.code.toLocaleLowerCase() === "enter") {
              formikLogin.submitForm();
            }
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <TextField
                label="Email"
                variant="standard"
                style={{ width: "100%" }}
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                }}
                name="userName"
                value={formikLogin.values?.userName}
                error={
                  formikLogin.touched.userName &&
                  Boolean(formikLogin.errors.userName)
                }
                onChange={formikLogin.handleChange}
                onBlur={formikLogin.handleBlur}
                helperText={
                  formikLogin.touched.userName && formikLogin.errors.userName
                }
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                label="Password"
                variant="standard"
                type={"password"}
                style={{ width: "100%" }}
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock />
                    </InputAdornment>
                  ),
                }}
                name="passWord"
                value={formikLogin.values?.passWord}
                error={
                  formikLogin.touched.passWord &&
                  Boolean(formikLogin.errors.passWord)
                }
                onChange={formikLogin.handleChange}
                onBlur={formikLogin.handleBlur}
                helperText={
                  formikLogin.touched.passWord && formikLogin.errors.passWord
                }
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button
                fullWidth
                variant="contained"
                onClick={() => {
                  formikLogin.submitForm();
                }}
              >
                Login
              </Button>
            </Grid>
            <Grid item xs={12} md={12} textAlign={"center"}>
              Not a member? <Link href={"/register"}>Register now</Link>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};
export default Login;
