import {
    Box,
    Button,
    Grid,
    Modal,
    Stack,
    TextField,
    Typography,
  } from "@mui/material";
  import React from "react";
  import {
    ButtonAction,
  } from "../../common/constant/constant";
import { ChangePasswordService } from "./service/changePasswordService";
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 3,
  };
  export interface ChangePasswordModalProps {
    onClose?: (action: ButtonAction) => void;
    show?: boolean
  }
  
  const ChangePasswordModal = ({
    onClose,
    show
  }: ChangePasswordModalProps) => {
    const { formikChangePassword } = ChangePasswordService({
      onClose: onClose,
    });
    return (
      <Modal open={show || false}>
        <Box sx={style}>
          <Typography style={{ marginBottom: 24 }} variant="h5">
            <b> Change password</b>
          </Typography>
          <Box>
            <Grid container spacing={2}>
              {/* <Grid item xs={12}>
                <TextField
                  label="User name"
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="userName"
                  value={formikChangePassword.values.userName}
                  error={
                    formikChangePassword.touched.userName &&
                    Boolean(formikChangePassword.errors.userName)
                  }
                  onChange={formikChangePassword.handleChange}
                  onBlur={formikChangePassword.handleBlur}
                  style={{ width: "100%" }}
                  size="small"
                  helperText={
                    formikChangePassword.touched.userName && formikChangePassword.errors.userName
                  }
                />
              </Grid> */}
              <Grid item xs={12}>
                <TextField
                  label="Current password"
                  name="currentPassword"
                  required
                  type="password"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={formikChangePassword.values.currentPassword}
                  error={
                    formikChangePassword.touched.currentPassword &&
                    Boolean(formikChangePassword.errors.currentPassword)
                  }
                  onChange={formikChangePassword.handleChange}
                  onBlur={formikChangePassword.handleBlur}
                  helperText={
                    formikChangePassword.touched.currentPassword && formikChangePassword.errors.currentPassword
                  }
                  style={{ width: "100%" }}
                  size="small"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Password"
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                  name="password"
                  value={formikChangePassword.values.password}
                  error={
                    formikChangePassword.touched.password &&
                    Boolean(formikChangePassword.errors.password)
                  }
                  helperText={
                    formikChangePassword.touched.password && formikChangePassword.errors.password
                  }
                  type="password"
                  onChange={formikChangePassword.handleChange}
                  onBlur={formikChangePassword.handleBlur}
                  style={{ width: "100%" }}
                  size="small"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Confirm password"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                  type="password"
                  style={{ width: "100%" }}
                  size="small"
                  name="confirmPassword"
                  value={formikChangePassword.values.confirmPassword}
                  error={
                    formikChangePassword.touched.confirmPassword &&
                    Boolean(formikChangePassword.errors.confirmPassword)
                  }
                  helperText={
                    formikChangePassword.touched.confirmPassword &&
                    formikChangePassword.errors.confirmPassword
                  }
                  onChange={formikChangePassword.handleChange}
                  onBlur={formikChangePassword.handleBlur}
                />
              </Grid>
              <Grid item xs={12}>
                <Stack spacing={2} direction="row" justifyContent={"flex-end"}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      onClose && onClose("Close");
                    }}
                  >
                    Close
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      formikChangePassword.handleSubmit();
                    }}
                  >
                    Save
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
    );
  };
  export default ChangePasswordModal;
  