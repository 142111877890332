import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Modal,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { ButtonAction, FormStatus } from "../../../../common/constant/constant";
import * as Api from "../../../../api/src";
import {
  UserAdminFormObj,
  userAdminService,
} from "../service/userAdminService";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 3,
};
export interface UserAdminModalProps {
  formStatus: FormStatus;
  userItem: UserAdminFormObj | null;
  onClose?: (action: ButtonAction) => void;
  companys: Api.Company[];
}

const UserAdminModal = ({
  formStatus,
  userItem,
  onClose,
  companys,
}: UserAdminModalProps) => {
  const { formikUser } = userAdminService({
    formStatus: formStatus,
    userItem: userItem,
    onClose: onClose,
    companys: companys,
  });
  return (
    <Modal open={!!formStatus}>
      <Box sx={style}>
        <Typography style={{ marginBottom: 24 }} variant="h5">
          <b> User</b>
        </Typography>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                label="First name"
                InputLabelProps={{
                  shrink: true,
                }}
                required
                name="firstName"
                value={formikUser.values.firstName}
                error={
                  formikUser.touched.firstName &&
                  Boolean(formikUser.errors.firstName)
                }
                onChange={formikUser.handleChange}
                onBlur={formikUser.handleBlur}
                style={{ width: "100%" }}
                size="small"
                helperText={
                  formikUser.touched.firstName && formikUser.errors.firstName
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Last name"
                name="lastName"
                required
                InputLabelProps={{
                  shrink: true,
                }}
                value={formikUser.values.lastName}
                error={
                  formikUser.touched.lastName &&
                  Boolean(formikUser.errors.lastName)
                }
                onChange={formikUser.handleChange}
                onBlur={formikUser.handleBlur}
                helperText={
                  formikUser.touched.lastName && formikUser.errors.lastName
                }
                style={{ width: "100%" }}
                size="small"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Email"
                name="email"
                required
                InputLabelProps={{
                  shrink: true,
                }}
                value={formikUser.values.email}
                error={
                  formikUser.touched.email && Boolean(formikUser.errors.email)
                }
                helperText={formikUser.touched.email && formikUser.errors.email}
                onChange={formikUser.handleChange}
                onBlur={formikUser.handleBlur}
                style={{ width: "100%" }}
                size="small"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Phone number"
                InputLabelProps={{
                  shrink: true,
                }}
                name="phoneNumber"
                value={formikUser.values.phoneNumber}
                error={
                  formikUser.touched.phoneNumber &&
                  Boolean(formikUser.errors.phoneNumber)
                }
                helperText={
                  formikUser.touched.phoneNumber &&
                  formikUser.errors.phoneNumber
                }
                onChange={formikUser.handleChange}
                onBlur={formikUser.handleBlur}
                style={{ width: "100%" }}
                size="small"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Password"
                InputLabelProps={{
                  shrink: true,
                }}
                required
                name="password"
                value={formikUser.values.password}
                error={
                  formikUser.touched.password &&
                  Boolean(formikUser.errors.password)
                }
                helperText={
                  formikUser.touched.password && formikUser.errors.password
                }
                type="password"
                onChange={formikUser.handleChange}
                onBlur={formikUser.handleBlur}
                style={{ width: "100%" }}
                size="small"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Confirm password"
                required
                InputLabelProps={{
                  shrink: true,
                }}
                type="password"
                style={{ width: "100%" }}
                size="small"
                name="confirmPassword"
                value={formikUser.values.confirmPassword}
                error={
                  formikUser.touched.confirmPassword &&
                  Boolean(formikUser.errors.confirmPassword)
                }
                helperText={
                  formikUser.touched.confirmPassword &&
                  formikUser.errors.confirmPassword
                }
                onChange={formikUser.handleChange}
                onBlur={formikUser.handleBlur}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Address"
                name="address"
                InputLabelProps={{
                  shrink: true,
                }}
                value={formikUser.values.address}
                error={
                  formikUser.touched.address &&
                  Boolean(formikUser.errors.address)
                }
                helperText={
                  formikUser.touched.address && formikUser.errors.address
                }
                onChange={formikUser.handleChange}
                onBlur={formikUser.handleBlur}
                style={{ width: "100%" }}
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <label style={{ fontSize: 12 }}>Status</label>
              <Switch
                name="status"
                checked={formikUser.values.status === Api.RowStatus.Active}
                onChange={(e, val) => {
                  formikUser.setFieldValue(
                    "status",
                    val ? Api.RowStatus.Active : Api.RowStatus.Inactive
                  );
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={2} direction="row" justifyContent={"flex-end"}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    onClose && onClose("Close");
                  }}
                >
                  Close
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    formikUser.handleSubmit();
                  }}
                >
                  Save
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};
export default UserAdminModal;
