import { FormStatus } from "../../../../common/constant/constant";
import { useFormik } from "formik";
import * as yup from "yup";
import * as Api from "../../../../api/src/index";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { MsgState } from "../../../../recoil/msgState";
import { CompanyModalProps } from "../modal/company";
import { AxiosResponse } from "axios";
import { DialogState } from "../../../../recoil/dialogState";
import { AppState } from "../../../../recoil/appState";
export interface UserFormObj extends Api.UserNomalCreateParam {
  confirmPassword: string;
}
export interface ListCompanyQuery {
  keyword: string;
  page: number;
  limit: number;
}
yup.addMethod(
  yup.number,
  "requiredNumber",
  function requiredNumber(errorMessage) {
    return this.test("requiredNumber", errorMessage, function (value) {
      const { path, createError, parent } = this;
      if (!value) {
        return createError({ path, message: errorMessage });
      }
      return true;
    });
  }
);
const validationSchema = yup.object({
  name: yup.string().required("Name is a required field"),
  phoneNumber: yup.string().required("Phone number is a required field"),
  email: yup.string().email().required("Email is a required field"),
  address: yup.string().required("Address is a required field"),
  countryId: (yup.number() as any).nullable("Country is a required field").requiredNumber("Country is a required field"),
});
const defaultObj = {
  id: 0,
  name: "",
  email: "",
  phoneNumber: "",
  address: "",
  description: "",
  countryId:undefined,
  status: Api.RowStatus.Active,
} as Api.CompanyEditParam;
const CompanyApi = new Api.CompanyApi();
export const companyService = ({
  companyItem,
  formStatus,
  onClose,
}: CompanyModalProps) => {
  const [, SetMsgState] = useRecoilState(MsgState);
  const [Countrys, setCountry] = useState<Api.Country[]>([]);
  useEffect(() => {
    new Api.CountryApi().apiCountryGetAllCountrysGet().then((res) => {
      setCountry(res?.data.result || []);
    });
  }, []);
  const formikCompany = useFormik({
    initialValues: companyItem || defaultObj,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      let res: AxiosResponse<
        Api.CompanyEditCommandResultOperationResult,
        any
      > | null = null;
      if (formStatus === "Add") {
        values.status = Api.RowStatus.Active;
        res = await CompanyApi.apiCompanyCreatePost({ param: values });
      } else {
        res = await CompanyApi.apiCompanyEditPut({ param: values });
      }
      if (res && res.data.errorMessage) {
        SetMsgState((cur) => {
          return { ...cur, Msg: res?.data.errorMessage || "", show: true };
        });
      }
      if (res && res.data.isSuccess && onClose) {
        onClose("Save");
      }
    },
  });
  const getSelectedValue = (id: number | undefined) => {
    const item = Countrys.find((o) => o.id === id || 0)
    if (item) {
      return { label: item.niceName, id: item.id }
    }
    return null
  }
  return { formikCompany, getSelectedValue, Countrys };
};
const defaultQuery = {
  keyword: "",
  page: 0,
  limit: 25,
};
export const listCompanyService = () => {
  const [appState, SetAppState] = useRecoilState(AppState);
  const [anchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const handleShowMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    item: Api.Company
  ) => {
    setMenuAnchorEl(event.currentTarget);
    setRowSelect(item);
  };
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };
  const [companyModalProp, setCompanyModalProp] = useState<CompanyModalProps>({
    formStatus: "",
    companyItem: null,
  });
  const [query, setQuery] = useState<ListCompanyQuery>();
  const [rowSelected, setRowSelect] = useState<Api.Company | null>(null);
  const [listData, setListData] =
    useState<Api.CompanyIPagedListOperationResult | null>(null);
  const [, setPropsDialog] = useRecoilState(DialogState);
  useEffect(() => {
    setQuery(defaultQuery);
    SetAppState({ ...appState, MenuSelected: "companys" });
  }, []);
  useEffect(() => {
    query && getCompanys(query);
  }, [query]);
  const getCompanys = async (param: ListCompanyQuery) => {
    const data = await CompanyApi.apiCompanyGetAllCompanysGet(
      param.keyword,
      param.page + 1,
      param.limit
    );
    setListData(data.data);
  };
  const onPageChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => {
    setQuery((cur) => {
      if (cur) return { ...cur, page: page };
    });
  };
  const onRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setQuery((cur) => {
      if (cur) return { ...cur, limit: parseInt(event.target.value) };
    });
  };
  const onKeyWordBlur = (value: string) => {
    if (query && value != query.keyword) {
      setQuery((cur) => {
        if (cur) return { ...cur, keyword: value };
      });
    }
  };
  const AddNew = () => {
    setCompanyModalProp((cur) => {
      return { ...cur, formStatus: "Add", companyItem: null };
    });
  };
  const EditObj = () => {
    setCompanyModalProp((cur) => {
      return {
        ...cur,
        formStatus: "Edit",
        companyItem: {
          id: rowSelected?.id,
          name: rowSelected?.name,
          description: rowSelected?.description,
          email: rowSelected?.email,
          phoneNumber: rowSelected?.phoneNumber,
          address: rowSelected?.address,
          status: rowSelected?.status,
          countryId: rowSelected?.countryId
        },
      };
    });
    handleMenuClose();
  };
  const updateStatus = async (rowStatus: Api.RowStatus) => {
    if (rowStatus === "Delete") {
      setPropsDialog({
        Msg: "Are you sure you want to delete this item?",
        show: true,
        DialogType: "Confirm",
        ActtionOK: () => {
          deleteCompany(rowStatus);
        },
      });
    }
  };
  const deleteCompany = async (rowStatus: Api.RowStatus) => {
    await CompanyApi.apiCompanyEditPut({
      param: {
        id: rowSelected?.id,
        name: rowSelected?.name,
        description: rowSelected?.description,
        email: rowSelected?.email,
        phoneNumber: rowSelected?.phoneNumber,
        address: rowSelected?.address,
        countryId: rowSelected?.countryId,
        status: rowStatus,
      },
    });
    query && getCompanys(query);
    handleMenuClose();
  };
  const closeModal = () => {
    setCompanyModalProp((cur) => {
      return { ...cur, formStatus: "", companyItem: null };
    });
  };
  return {
    handleShowMenuClick,
    handleMenuClose,
    AddNew,
    EditObj,
    getCompanys,
    listData,
    anchorEl,
    query,
    companyModalProp,
    onPageChange,
    onRowsPerPageChange,
    onKeyWordBlur,
    openMenu,
    closeModal,
    updateStatus,
  };
};
