import * as React from "react";
import IconButton from "@mui/material/IconButton";
import { Avatar, ListItemIcon, Menu, MenuItem, Tooltip } from "@mui/material";
import { Logout, PersonAdd, Settings,SyncLock } from "@mui/icons-material";
import { AuthenService } from "../../services/authenServices";
import ChangePasswordModal from "./changePassword";
function UserAvatar() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showChangePassword, setShowChangePassword] = React.useState<boolean>(false);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { GetUserInfo, handleLogout } = AuthenService();
  return (
    <>
      <div>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={openMenu ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openMenu ? "true" : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }}>
              {GetUserInfo()
                ?.user_info?.lastName?.substring(0, 1)
                .trim()
                .toString()
                .toUpperCase() ||
                GetUserInfo()
                  ?.user_info?.firstName?.substring(0, 1)
                  .toString()
                  .toUpperCase()}
            </Avatar>
          </IconButton>
        </Tooltip>
      </div>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={openMenu}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {/* <MenuItem onClick={handleClose}>
          <Avatar /> Profile
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Avatar /> My account
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <PersonAdd fontSize="small" />
          </ListItemIcon>
          Add another account
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem> */}
        <MenuItem onClick={() => {
          setShowChangePassword(true);
          handleClose();
        }}>
          <ListItemIcon>
            <SyncLock fontSize="small" />
          </ListItemIcon>
          Change password
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleLogout();
            handleClose();
          }}
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
      {showChangePassword && (
        <ChangePasswordModal show={true}  onClose={() => {
          setShowChangePassword(false);
        }}></ChangePasswordModal>
      )}
    </>
  );
}

export default UserAvatar;
