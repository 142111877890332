import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import React from "react";
import { registerCompanyUserService } from "./service/registerService";
import * as Api from "../../../api/src";
import { CheckBox } from "@mui/icons-material";
let TIMEOUT: any = null;
export const OrganizationRegister = ({
  onSubmit,
  countrys,
}: {
  onSubmit: (data: any) => void;
  countrys: Api.Country[];
}) => {
  const { formikRegisterCompany, getCompanyByLicenseKey } =
    registerCompanyUserService({
      onSubmit: onSubmit,
    });
  const [selectedValue, setSelectedValue] = React.useState<"Trial" | "Full">(
    "Trial"
  );
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formikRegisterCompany.setFieldValue("user.licenseKey", "");
    formikRegisterCompany.setFieldValue(
      "user.licenseType",
      event.target.value == "Full" ? "Full" : "Trial"
    );
    setSelectedValue(event.target.value == "Full" ? "Full" : "Trial");
  };
  const getSelectedValue = (id: number | undefined) => {
    const item = countrys.find((o) => o.id === id || 0);
    if (item) {
      return { label: item.niceName, id: item.id };
    }
    return null;
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <h4>User Info</h4>
        <div
          style={{
            borderTop: "1px solid  rgb(161, 159, 157)",
            width: "100%",
            height: 1,
          }}
        ></div>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label="First name"
          required
          variant="standard"
          style={{ width: "100%" }}
          size="small"
          name="user.firstName"
          value={formikRegisterCompany.values.user?.firstName}
          error={
            formikRegisterCompany.touched.user?.firstName &&
            Boolean(formikRegisterCompany.errors.user?.firstName)
          }
          onChange={formikRegisterCompany.handleChange}
          onBlur={formikRegisterCompany.handleBlur}
          helperText={
            formikRegisterCompany.touched.user?.firstName &&
            formikRegisterCompany.errors.user?.firstName
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label="Last name"
          required
          variant="standard"
          style={{ width: "100%" }}
          size="small"
          name="user.lastName"
          value={formikRegisterCompany.values.user?.lastName}
          error={
            formikRegisterCompany.touched.user?.lastName &&
            Boolean(formikRegisterCompany.errors.user?.lastName)
          }
          onChange={formikRegisterCompany.handleChange}
          onBlur={formikRegisterCompany.handleBlur}
          helperText={
            formikRegisterCompany.touched.user?.lastName &&
            formikRegisterCompany.errors.user?.lastName
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label="Address"
          variant="standard"
          style={{ width: "100%" }}
          size="small"
          name="user.address"
          value={formikRegisterCompany.values.user?.address}
          error={
            formikRegisterCompany.touched.user?.address &&
            Boolean(formikRegisterCompany.errors.user?.address)
          }
          onChange={formikRegisterCompany.handleChange}
          onBlur={formikRegisterCompany.handleBlur}
          helperText={
            formikRegisterCompany.touched.user?.address &&
            formikRegisterCompany.errors.user?.address
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label="Phone number"
          variant="standard"
          style={{ width: "100%" }}
          size="small"
          name="user.phoneNumber"
          value={formikRegisterCompany.values.user?.phoneNumber}
          error={
            formikRegisterCompany.touched.user?.phoneNumber &&
            Boolean(formikRegisterCompany.errors.user?.phoneNumber)
          }
          onChange={formikRegisterCompany.handleChange}
          onBlur={formikRegisterCompany.handleBlur}
          helperText={
            formikRegisterCompany.touched.user?.phoneNumber &&
            formikRegisterCompany.errors.user?.phoneNumber
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          required
          label="Email"
          variant="standard"
          style={{ width: "100%" }}
          size="small"
          name="user.email"
          value={formikRegisterCompany.values.user?.email}
          error={
            formikRegisterCompany.touched.user?.email &&
            Boolean(formikRegisterCompany.errors.user?.email)
          }
          onChange={formikRegisterCompany.handleChange}
          onBlur={formikRegisterCompany.handleBlur}
          helperText={
            formikRegisterCompany.touched.user?.email &&
            formikRegisterCompany.errors.user?.email
          }
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        style={{ display: "flex", alignItems: "center" }}
      >
        <Checkbox
          size="small"
          name="sendMail"
          checked={formikRegisterCompany.values.sendMail}
          onChange={formikRegisterCompany.handleChange}
          onBlur={formikRegisterCompany.handleBlur}
        />
        <label>Send registration email</label>
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          label="Password"
          type="password"
          required
          variant="standard"
          style={{ width: "100%" }}
          size="small"
          name="user.password"
          value={formikRegisterCompany.values.user?.password}
          error={
            formikRegisterCompany.touched.user?.password &&
            Boolean(formikRegisterCompany.errors.user?.password)
          }
          onChange={formikRegisterCompany.handleChange}
          onBlur={formikRegisterCompany.handleBlur}
          helperText={
            formikRegisterCompany.touched.user?.password &&
            formikRegisterCompany.errors.user?.password
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label="Confirm password"
          required
          variant="standard"
          style={{ width: "100%" }}
          type="password"
          size="small"
          name="user.confirmPassword"
          value={formikRegisterCompany.values.user?.confirmPassword}
          error={
            formikRegisterCompany.touched.user?.confirmPassword &&
            Boolean(formikRegisterCompany.errors.user?.confirmPassword)
          }
          onChange={formikRegisterCompany.handleChange}
          onBlur={formikRegisterCompany.handleBlur}
          helperText={
            formikRegisterCompany.touched.user?.confirmPassword &&
            formikRegisterCompany.errors.user?.confirmPassword
          }
        />
      </Grid>

      {/* <Grid item xs={12} md={6}>
        <Autocomplete
          options={countrys.map((o) => {
            return { label: o.niceName, id: o.id };
          })}
          value={getSelectedValue(formikRegisterCompany.values.user?.countryId)}
          size="small"
          sx={{ width: "100%" }}
          onChange={(o, val) => {
            formikRegisterCompany.setFieldValue("user.countryId", val?.id || 0);
          }}
          renderInput={(params) => (
            <TextField
              required
              name="user.countryId"
              {...params}
              variant="standard"
              value={formikRegisterCompany.values.user.countryId}
              error={
                formikRegisterCompany.touched.user?.countryId &&
                Boolean(formikRegisterCompany.errors.user?.countryId)
              }
              helperText={
                formikRegisterCompany.touched.user?.countryId &&
                formikRegisterCompany.errors.user?.countryId
              }
              onChange={formikRegisterCompany.handleChange}
              onBlur={formikRegisterCompany.handleBlur}
              label="Country"
            />
          )}
        />
      </Grid> */}

      <Grid item xs={12} md={12}>
        <h4>Company Info</h4>
        <div
          style={{
            borderTop: "1px solid  rgb(161, 159, 157)",
            width: "100%",
            height: 1,
          }}
        ></div>
      </Grid>
      <Grid item xs={12} md={12}>
        <TextField
          required
          label="Name"
          disabled={selectedValue == "Full"}
          variant="standard"
          style={{ width: "100%" }}
          size="small"
          name="company.name"
          value={formikRegisterCompany.values.company?.name}
          error={
            formikRegisterCompany.touched.company?.name &&
            Boolean(formikRegisterCompany.errors.company?.name)
          }
          onChange={formikRegisterCompany.handleChange}
          onBlur={formikRegisterCompany.handleBlur}
          helperText={
            formikRegisterCompany.touched.company?.name &&
            formikRegisterCompany.errors.company?.name
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label="Email"
          disabled={selectedValue == "Full"}
          required
          variant="standard"
          style={{ width: "100%" }}
          size="small"
          name="company.email"
          value={formikRegisterCompany.values.company?.email}
          error={
            formikRegisterCompany.touched.company?.email &&
            Boolean(formikRegisterCompany.errors.company?.email)
          }
          onChange={formikRegisterCompany.handleChange}
          onBlur={formikRegisterCompany.handleBlur}
          helperText={
            formikRegisterCompany.touched.company?.email &&
            formikRegisterCompany.errors.company?.email
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label="Phone number"
          required
          disabled={selectedValue == "Full"}
          variant="standard"
          style={{ width: "100%" }}
          size="small"
          name="company.phoneNumber"
          value={formikRegisterCompany.values.company?.phoneNumber}
          error={
            formikRegisterCompany.touched.company?.phoneNumber &&
            Boolean(formikRegisterCompany.errors.company?.phoneNumber)
          }
          onChange={formikRegisterCompany.handleChange}
          onBlur={formikRegisterCompany.handleBlur}
          helperText={
            formikRegisterCompany.touched.company?.phoneNumber &&
            formikRegisterCompany.errors.company?.phoneNumber
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Autocomplete
          options={countrys.map((o) => {
            return { label: o.niceName, id: o.id };
          })}
          value={getSelectedValue(
            formikRegisterCompany.values.company?.countryId
          )}
          size="small"
          disabled={selectedValue == "Full"}
          sx={{ width: "100%" }}
          onChange={(o, val) => {
            console.log(val);
            formikRegisterCompany.setFieldValue(
              "company.countryId",
              val?.id || 0
            );
            formikRegisterCompany.setFieldValue("user.countryId", val?.id || 0);
          }}
          renderInput={(params) => (
            <TextField
              required
              name="company.countryId"
              {...params}
              variant="standard"
              value={formikRegisterCompany.values.company?.countryId}
              error={
                formikRegisterCompany.touched.company?.countryId &&
                Boolean(formikRegisterCompany.errors.company?.countryId)
              }
              helperText={
                formikRegisterCompany.touched.company?.countryId &&
                formikRegisterCompany.errors.company?.countryId
              }
              onChange={formikRegisterCompany.handleChange}
              onBlur={formikRegisterCompany.handleBlur}
              label="Country"
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label="Address"
          required
          disabled={selectedValue == "Full"}
          variant="standard"
          style={{ width: "100%" }}
          size="small"
          name="company.address"
          value={formikRegisterCompany.values.company?.address}
          error={
            formikRegisterCompany.touched.company?.address &&
            Boolean(formikRegisterCompany.errors.company?.address)
          }
          onChange={formikRegisterCompany.handleChange}
          onBlur={formikRegisterCompany.handleBlur}
          helperText={
            formikRegisterCompany.touched.company?.address &&
            formikRegisterCompany.errors.company?.address
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <RadioGroup
          row
          aria-labelledby="demo-form-control-label-placement"
          name="position"
          defaultValue="top"
        >
          <FormControlLabel
            value="end"
            control={
              <Radio
                checked={selectedValue === "Trial"}
                onChange={handleChange}
                value="Trial"
                name="radio-buttons"
              />
            }
            label="Trial"
          />
          <FormControlLabel
            value="end"
            control={
              <Radio
                checked={selectedValue === "Full"}
                onChange={handleChange}
                value="Full"
                name="radio-buttons"
              />
            }
            label="Full license"
          />
        </RadioGroup>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label="License key"
          variant="standard"
          disabled={selectedValue == "Trial"}
          style={{ width: "100%" }}
          size="small"
          name="user.licenseKey"
          value={formikRegisterCompany.values.user?.licenseKey}
          error={
            formikRegisterCompany.touched.user?.licenseKey &&
            Boolean(formikRegisterCompany.errors.user?.licenseKey)
          }
          onChange={(e) => {
            formikRegisterCompany.handleChange(e);
            if (TIMEOUT) {
              clearTimeout(TIMEOUT);
            }
            TIMEOUT = setTimeout(() => {
              getCompanyByLicenseKey(e.target.value);
              TIMEOUT = null;
            }, 300);
          }}
          onBlur={(e) => {
            formikRegisterCompany.handleBlur(e);
          }}
          helperText={
            formikRegisterCompany.touched.user?.licenseKey &&
            formikRegisterCompany.errors.user?.licenseKey
          }
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        style={{ textAlign: "right", display: "flex", alignItems: "center" }}
      >
        <div style={{ flex: 1 }}></div>
        <Button
          variant="contained"
          onClick={() => {
            formikRegisterCompany.submitForm();
          }}
        >
          Register
        </Button>
      </Grid>
    </Grid>
  );
};
