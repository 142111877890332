import { useFormik } from "formik";
import * as yup from "yup";
import * as Api from "../../../../api/src/index";
import { useRecoilState } from "recoil";
import { MsgState } from "../../../../recoil/msgState";
import { useNavigate } from "react-router-dom";
import { LocalStorageKey } from "../../../../common/constant/constant";
import { useEffect } from "react";
const UserApi = new Api.UserApi();
const validationUserSchema = yup.object({
  userName: yup.string().required("User name is a required field"),
  passWord: yup.string().required("Password is a required field"),
});

const defaultUserObj = {
  userName: "",
  passWord: "",
};
export const LoginService = () => {
  const navigate = useNavigate();
  const [, SetMsgState] = useRecoilState(MsgState);
  const formikLogin = useFormik({
    initialValues: defaultUserObj,
    validationSchema: validationUserSchema,
    onSubmit: async (values) => {
      const res = await UserApi.apiUserLoginPost(values);
      if (res && res.data.errorMessage) {
        SetMsgState((cur) => {
          return { ...cur, Msg: res?.data.errorMessage || "", show: true };
        });
      } else {
        localStorage.setItem(LocalStorageKey.UserInfo, JSON.stringify(res.data.result));
        navigate("/users");
      }
    },
  });
  const Logout = () => {
    if (localStorage.getItem(LocalStorageKey.UserInfo))
      UserApi.apiUserLogoutPost().then(() => {
        localStorage.removeItem(LocalStorageKey.UserInfo);
      });
  };
  useEffect(() => {
    Logout();
  }, []);
  return { formikLogin };
};
