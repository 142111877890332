import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Modal,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { ButtonAction, FormStatus } from "../../../../common/constant/constant";
import { UserFormObj, companyService } from "../service/companyService";
import * as Api from "../../../../api/src";
import { Label } from "@mui/icons-material";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 3,
};
export interface CompanyModalProps {
  formStatus: FormStatus;
  companyItem: Api.CompanyEditParam | null;
  onClose?: (Action: ButtonAction) => void;
}

const CompanyModal = ({
  formStatus,
  companyItem,
  onClose,
}: CompanyModalProps) => {
  const { formikCompany, Countrys, getSelectedValue } = companyService({
    formStatus: formStatus,
    companyItem: companyItem,
    onClose,
  });
  return (
    <Modal open={!!formStatus}>
      <Box sx={style}>
        <Typography style={{ marginBottom: 24 }} variant="h5">
          <b> Company</b>
        </Typography>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                label="Name"
                InputLabelProps={{
                  shrink: true,
                }}
                required
                name="name"
                value={formikCompany.values.name}
                error={
                  formikCompany.touched.name &&
                  Boolean(formikCompany.errors.name)
                }
                onChange={formikCompany.handleChange}
                onBlur={formikCompany.handleBlur}
                style={{ width: "100%" }}
                size="small"
                helperText={
                  formikCompany.touched.name && formikCompany.errors.name
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Email"
                name="email"
                required
                InputLabelProps={{
                  shrink: true,
                }}
                value={formikCompany.values.email}
                error={
                  formikCompany.touched.email &&
                  Boolean(formikCompany.errors.email)
                }
                onChange={formikCompany.handleChange}
                onBlur={formikCompany.handleBlur}
                helperText={
                  formikCompany.touched.email && formikCompany.errors.email
                }
                style={{ width: "100%" }}
                size="small"
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                options={Countrys.map((o) => {
                  return { label: o.niceName, id: o.id };
                })}
                value={getSelectedValue(formikCompany.values.countryId || 0)}
                size="small"
                sx={{ width: "100%" }}
                onChange={(o, val) => {
                  formikCompany.setFieldValue("countryId", val?.id || 0);
                }}
                renderInput={(params) => (
                  <TextField
                    required
                    name="countryId"
                    {...params}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={formikCompany.values.countryId}
                    error={
                      formikCompany.touched.countryId &&
                      Boolean(formikCompany.errors.countryId)
                    }
                    helperText={
                      formikCompany.touched.countryId &&
                      formikCompany.errors.countryId
                    }
                    onChange={formikCompany.handleChange}
                    onBlur={formikCompany.handleBlur}
                    label="Country"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Address"
                required
                name="address"
                InputLabelProps={{
                  shrink: true,
                }}
                value={formikCompany.values.address}
                error={
                  formikCompany.touched.address &&
                  Boolean(formikCompany.errors.address)
                }
                helperText={
                  formikCompany.touched.address && formikCompany.errors.address
                }
                onChange={formikCompany.handleChange}
                onBlur={formikCompany.handleBlur}
                style={{ width: "100%" }}
                size="small"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Phone number"
                required
                InputLabelProps={{
                  shrink: true,
                }}
                name="phoneNumber"
                value={formikCompany.values.phoneNumber}
                error={
                  formikCompany.touched.phoneNumber &&
                  Boolean(formikCompany.errors.phoneNumber)
                }
                helperText={
                  formikCompany.touched.phoneNumber &&
                  formikCompany.errors.phoneNumber
                }
                onChange={formikCompany.handleChange}
                onBlur={formikCompany.handleBlur}
                style={{ width: "100%" }}
                size="small"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Description"
                name="description"
                InputLabelProps={{
                  shrink: true,
                }}
                value={formikCompany.values.description}
                error={
                  formikCompany.touched.description &&
                  Boolean(formikCompany.errors.description)
                }
                helperText={
                  formikCompany.touched.description &&
                  formikCompany.errors.description
                }
                onChange={formikCompany.handleChange}
                onBlur={formikCompany.handleBlur}
                style={{ width: "100%" }}
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <label style={{ fontSize: 12 }}>Status</label>
              <Switch
                name="status"
                checked={formikCompany.values.status === Api.RowStatus.Active}
                onChange={(e, val) => {
                  formikCompany.setFieldValue(
                    "status",
                    val ? Api.RowStatus.Active : Api.RowStatus.Inactive
                  );
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={2} direction="row" justifyContent={"flex-end"}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    onClose && onClose("Close");
                  }}
                >
                  Close
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    formikCompany.handleSubmit();
                  }}
                >
                  Save
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};
export default CompanyModal;
