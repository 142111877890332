import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import "./App.css";
import LeftMenu from "./components/menu/leftmenu";
import Users from "./components/pages/user/users";
import { useEffect } from "react";
import configAxios from "./api/configAxios";
import Companys from "./components/pages/company/companys";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import {
  Alert,
  Avatar,
  ListItemIcon,
  Menu,
  MenuItem,
  Snackbar,
  Tooltip,
} from "@mui/material";
import { MsgState } from "./recoil/msgState";
import { useRecoilState } from "recoil";
import { AppState } from "./recoil/appState";
import { ConfirmDialog } from "./dialog/confimDialog";
import { Logout, PersonAdd, Settings } from "@mui/icons-material";
import UserAvatar from "./components/avata/Avatar";
import { AuthenService } from "./services/authenServices";
const drawerWidth = 240;
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));
function Layout() {
  const theme = useTheme();
  const [msgState, SetMsgState] = useRecoilState(MsgState);
  const [appState, SetAppState] = useRecoilState(AppState);
  const [open, setOpen] = React.useState(true);
  const { GetUserInfo } = AuthenService();
  useEffect(() => {
    configAxios();
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleSnackbarClose = () => {
    SetMsgState((cur) => {
      return { ...cur, Msg: "", show: false };
    });
  };
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  if (!GetUserInfo()) return <></>;
  return (
    <>
      <Snackbar
        open={msgState.show}
        autoHideDuration={10000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        style={{ width: "100%" }}
        onClose={handleSnackbarClose}
      >
        <Alert
          severity="error"
          onClose={handleSnackbarClose}
          sx={{ width: "100%" }}
        >
          {msgState.Msg}
        </Alert>
      </Snackbar>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: "none" }) }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              display={"contents"}
              noWrap
              component="div"
            >
              <div style={{ flex: 1 }}> {appState.MenuSelected}</div>
              <div>
                <UserAvatar></UserAvatar>
              </div>
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <LeftMenu></LeftMenu>
        </Drawer>
        <Main open={open}>
          <DrawerHeader />
          <Outlet></Outlet>
        </Main>
      </Box>
      <ConfirmDialog></ConfirmDialog>
    </>
  );
}

export default Layout;
