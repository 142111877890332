import React, { useEffect } from "react";
import { Tab, Tabs } from "@mui/material";
import UserNomals from "./userNomals";
import UserSysAdmins from "./userSysAdmins";
import { AppState } from "../../../recoil/appState";
import { useRecoilState } from "recoil";
import { AuthenService } from "../../../services/authenServices";
import { Role } from "../../../common/constant/constant";
const Users = () => {
  const { GetRoles } = AuthenService();
  const [appState, SetAppState] = useRecoilState(AppState);
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  useEffect(() => {
    SetAppState({ ...appState, MenuSelected: "users" });
  }, []);
  return (
    <>
      <Tabs value={value} onChange={handleChange}>
        <Tab value={0} label="Users" />
        {GetRoles().includes(Role.sysadmin) && (
          <Tab value={1} label="System Admin users" />
        )}
      </Tabs>
      {value === 0 && <UserNomals></UserNomals>}
      {value === 1 && GetRoles().includes(Role.sysadmin) && (
        <UserSysAdmins></UserSysAdmins>
      )}
    </>
  );
};
export default Users;
