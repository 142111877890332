import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { DialogState } from "../recoil/dialogState";
import { useRecoilState } from "recoil";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export const ConfirmDialog = () => {
  const [propsDialog, setPropsDialog] = useRecoilState(DialogState);

  const handleClose = () => {
    setPropsDialog({
      Msg: "",
      show: false,
      DialogType: "",
      ActtionCancel: undefined,
      ActtionOK: undefined,
    });
  };

  return (
    <Dialog
      open={propsDialog.show}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{propsDialog.DialogType}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {propsDialog.Msg}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {propsDialog.DialogType === "Confirm" && (
          <Button onClick={handleClose}>Cancel</Button>
        )}
        <Button
          onClick={() => {
            propsDialog.ActtionOK && propsDialog.ActtionOK();
            handleClose();
          }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
