import { useFormik } from "formik";
import * as yup from "yup";
import * as Api from "../../../api/src/index";
import { useRecoilState } from "recoil";
import { MsgState } from "../../../recoil/msgState";
import { ChangePasswordModalProps } from '../changePassword';
import { AuthenService } from "../../../services/authenServices";
const UserApi = new Api.UserApi();
yup.addMethod(
  yup.string,
  "checkConfirmPassword",
  function checkConfirmPassword(errorMessage) {
    return this.test("checkConfirmPassword", errorMessage, function (value) {
      const { path, createError, parent } = this;
      if (parent.password !== parent.confirmPassword) {
        return createError({ path, message: errorMessage });
      }
      return true;
    });
  }
);
const validationSchema = yup.object({
//   userName: yup.string().required("User name is a required field"),
  currentPassword: yup
    .string()
    .required("Current password is a required field"),
  password: yup
    .string()
    .required("Password is a required field")
    .min(6, "Password at least 6 character please"),
  confirmPassword: (yup.string() as any).checkConfirmPassword(
    "Confirm  password doesn't match"
  ),
});
const defaultUserObj = {
  userName: "",
  currentPassword: "",
  password: "",
  confirmPassword: "",
};
export const ChangePasswordService = ({
    onClose
}:ChangePasswordModalProps) => {
  const [, SetMsgState] = useRecoilState(MsgState);
  const { GetUserInfo, } = AuthenService();
  const formikChangePassword = useFormik({
    initialValues: defaultUserObj,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
        values.userName =  GetUserInfo()?.user_info?.userName ||""
        const res = await UserApi.apiUserChangePasswordPost(values);
      if (res && res.data.errorMessage) {
        SetMsgState((cur) => {
          return { ...cur, Msg: res?.data.errorMessage || "", show: true };
        });
      } else {
        onClose && onClose('Save')
      }
    },
  });
  return { formikChangePassword };
};
