import React from "react";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import ApartmentIcon from "@mui/icons-material/Apartment";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import { AppState } from "../../recoil/appState";
import { useRecoilState } from "recoil";
import { Role } from "../../common/constant/constant";
import { AuthenService } from "../../services/authenServices";
const Link = React.forwardRef<HTMLAnchorElement, RouterLinkProps>(function Link(
  itemProps,
  ref
) {
  return <RouterLink ref={ref} {...itemProps} role={undefined} />;
});
interface PageLink {
  Text: string;
  Role: string;
  Url: string;
}
const pages = [
  {
    Text: "Users",
    Url: "/users",
  },
  {
    Text: "Companys",
    Role: Role.sysadmin,
    Url: "/companys",
  },
] as PageLink[];

const LeftMenu = () => {
  const { GetRoles } = AuthenService();
  const [appState] = useRecoilState(AppState);
  return (
    <>
      <List>
        {pages.map((item, index) => {
          if (item.Role && !GetRoles().includes(item.Role)) {
            return <></>;
          }
          return (
            <ListItem
              key={item.Text}
              component={Link}
              disablePadding
              to={item.Url}
            >
              <ListItemButton
                selected={
                  appState.MenuSelected.toLowerCase() ===
                  item.Text.toLowerCase()
                }
              >
                <ListItemIcon>
                  {index % 2 === 0 ? <PersonIcon /> : <ApartmentIcon />}
                </ListItemIcon>
                <ListItemText style={{ color: "#333" }} primary={item.Text} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </>
  );
};
export default LeftMenu;
