import { useFormik } from "formik";
import * as yup from "yup";
import * as Api from "../../../../api/src/index";
import { useRecoilState } from "recoil";
import { MsgState } from "../../../../recoil/msgState";
yup.addMethod(
  yup.string,
  "checkConfirmPassword",
  function checkConfirmPassword(errorMessage) {
    return this.test("checkConfirmPassword", errorMessage, function (value) {
      const { path, createError, parent } = this;
      if (parent.password !== parent.confirmPassword) {
        return createError({ path, message: errorMessage });
      }
      return true;
    });
  }
);
yup.addMethod(
  yup.string,
  "CheckLicenseKey",
  function CheckLicenseKey(errorMessage) {
    return this.test("CheckLicenseKey", errorMessage, function (value) {
      const { path, createError, parent } = this;
      if (!parent.companyId && parent.licenseType != 'Trial') {
        return createError({ path, message: errorMessage });
      }
      return true;
    });
  }
);
yup.addMethod(
  yup.string,
  "requiredLicenseKey",
  function requiredLicenseKey(errorMessage) {
    return this.test("requiredLicenseKey", errorMessage, function (value) {
      const { path, createError, parent } = this;
      if (!value && parent.licenseType != 'Trial') {
        return createError({ path, message: errorMessage });
      }
      return true;
    });
  }
);
yup.addMethod(
  yup.number,
  "requiredNumber",
  function requiredNumber(errorMessage) {
    return this.test("requiredNumber", errorMessage, function (value) {
      const { path, createError, parent } = this;
      if (!value) {
        return createError({ path, message: errorMessage });
      }
      return true;
    });
  }
);
const validationSchema = yup.object({
  user: yup.object({
    firstName: yup.string().required("First name is a required field"),
    // countryId: (yup.number() as any).nullable("Country is a required field").requiredNumber("Country is a required field"),
    lastName: yup.string().required("Last name is a required field"),
    email: yup.string().email().required("Email is a required field"),
    password: yup
      .string()
      .required("Password is a required field")
      .min(6, "Password at least 6 character please"),
    confirmPassword: (yup.string() as any).checkConfirmPassword(
      "Confirm password doesn't match"
    ),
    licenseKey: (yup.string() as any).requiredLicenseKey("License key is a required field").CheckLicenseKey(
      "License key not exists"
    ),
  }),
  company: yup.object({
    name: yup.string().required("Name is a required field"),
    // countryId: (yup.number() as any).nullable("Country is a required field").requiredNumber("Country is a required field"),
    phoneNumber: yup.string().required("Phone number is a required field"),
    address: yup.string().required("Address is a required field"),
    email: yup.string().email().required("Email is a required field"),
  }),
});
const validationUserSchema = yup.object({
  firstName: yup.string().required("First name is a required field"),
  countryId: (yup.number() as any).nullable("Country is a required field").requiredNumber("Country is a required field"),
  lastName: yup.string().required("Last name is a required field"),
  email: yup.string().email().required("Email is a required field"),
  password: yup
    .string()
    .required("Password is a required field")
    .min(6, "Password at least 6 character please"),
  confirmPassword: (yup.string() as any).checkConfirmPassword(
    "Confirm  password doesn't match"
  ),
});

const defaultUserObj = {
  id: 0,
  userName: "",
  firstName: "",
  lastName: "",
  phoneNumber: "",
  address: "",
  email: "",
  password: "",
  confirmPassword: "",
  companyId: undefined,
  countryId: undefined,
  status: Api.RowStatus.Active,
};
const defaultObj = {
  user: {
    id: 0,
    userName: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    address: "",
    email: "",
    password: "",
    confirmPassword: "",
    companyId: undefined,
    countryId: undefined,
    status: Api.RowStatus.Active,
    licenseKey: "",
    licenseType: 'Trial'
  },
  company: {
    id: 0,
    name: "",
    email: "",
    phoneNumber: "",
    address: "",
    description: "",
    status: Api.RowStatus.Active,
    countryId: undefined,
  },
  sendMail: false,
};
const UserApi = new Api.UserApi();
export const registerCompanyUserService = (props: { onSubmit: (data: any) => void }) => {
  const [, SetMsgState] = useRecoilState(MsgState);
  const formikRegisterCompany = useFormik({
    initialValues: defaultObj,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      let res: any | null = null;
      values = {
        ...values,
        user: {
          ...values.user,
          userName: values.user?.email,
          status: Api.RowStatus.Active,
        },
      };
      if (values.user.companyId) {
        res = await UserApi.apiUserRegisterUserPost({
          param: { ...values.user },
        });
        if (res && res.data.errorMessage) {
          SetMsgState((cur) => {
            return { ...cur, Msg: res?.data.errorMessage || "", show: true };
          });
        } else {
          props.onSubmit(values);
          formikRegisterCompany.resetForm();
        }
      } else {
        res = await UserApi.apiUserRegisterCompanyPost({ param: values });
        if (res && res.data.errorMessage) {
          SetMsgState((cur) => {
            return { ...cur, Msg: res?.data.errorMessage || "", show: true };
          });
        } else {
          props.onSubmit(values);
          formikRegisterCompany.resetForm();
        }
      }
    },
  });
  const getCompanyByLicenseKey = async (key: string) => {
    let company = null;
    if (key) {
      company = await new Api.CompanyApi().apiCompanyGetCompanyByLicenseKeyPost(key);
    }
    formikRegisterCompany.setFieldValue("user.countryId", company?.data.countryId || undefined);
    formikRegisterCompany.setFieldValue("user.companyId", company?.data.id || undefined);
    formikRegisterCompany.setFieldValue("company.name", company?.data.name || '');
    formikRegisterCompany.setFieldValue("company.email", company?.data.email || '');
    formikRegisterCompany.setFieldValue("company.phoneNumber", company?.data.phoneNumber || '');
    formikRegisterCompany.setFieldValue("company.address", company?.data.address || '');
    formikRegisterCompany.setFieldValue("company.description", company?.data.description || '');
    formikRegisterCompany.setFieldValue("company.countryId", company?.data.countryId || 0);
  }
  return { formikRegisterCompany, getCompanyByLicenseKey };
};
export const registerUserService = (props: { onSubmit: () => void }) => {
  const [, SetMsgState] = useRecoilState(MsgState);
  const formikRegisterUser = useFormik({
    initialValues: defaultUserObj,
    validationSchema: validationUserSchema,
    onSubmit: async (values) => {
      // let res: any | null = null;
      // res = await UserApi.apiUserRegisterUserPost({
      //   param: { ...values, userName: values.email },
      // });
      // if (res && res.data.errorMessage) {
      //   SetMsgState((cur) => {
      //     return { ...cur, Msg: res?.data.errorMessage || "", show: true };
      //   });
      // } else {
      //   props.onSubmit();
      //   formikRegisterUser.resetForm();
      // }
    },
  });
  return { formikRegisterUser };
};
