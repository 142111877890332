import { FormStatus, Role } from "./../../../../common/constant/constant";
import { useFormik } from "formik";
import * as yup from "yup";
import * as Api from "../../../../api/src/index";
import { useEffect, useState } from "react";
import { UserModalProps } from "../modal/user";
import { useRecoilState } from "recoil";
import { MsgState } from "../../../../recoil/msgState";
import { DialogState } from "../../../../recoil/dialogState";
import { AuthenService } from "../../../../services/authenServices";
export interface UserFormObj extends Api.UserNomalCreateParam {
  confirmPassword?: string;
}

yup.addMethod(
  yup.string,
  "checkConfirmPassword",
  function checkConfirmPassword(errorMessage) {
    return this.test("checkConfirmPassword", errorMessage, function (value) {
      const { path, createError, parent } = this;
      if (parent.password !== parent.confirmPassword) {
        return createError({ path, message: errorMessage });
      }
      return true;
    });
  }
);

yup.addMethod(
  yup.number,
  "requiredNumber",
  function requiredNumber(errorMessage) {
    return this.test("requiredNumber", errorMessage, function (value) {
      const { path, createError, parent } = this;
      if (!value) {
        return createError({ path, message: errorMessage });
      }
      return true;
    });
  }
);
const validationSchema = yup.object({
  firstName: yup.string().required("First name is a required field"),
  lastName: yup.string().required("Last name is a required field"),
  email: yup.string().email().required("Email is a required field"),
  password: yup.string().min(6, "Password at least 6 character please"),
  // countryId: (yup.number() as any).nullable("Country is a required field").requiredNumber("Country is a required field"),
  confirmPassword: (yup.string() as any).checkConfirmPassword(
    "Confirm  password doesn't match"
  ),
});
const defaultObj = {
  id: 0,
  userName: "",
  firstName: "",
  lastName: "",
  phoneNumber: "",
  address: "",
  email: "",
  passWord: "",
  companyId: undefined,
  countryId: undefined,
  status: Api.RowStatus.Active,
} as UserFormObj;
const UserApi = new Api.UserApi();
const CompanyApi = new Api.CompanyApi();
export const userService = ({
  userItem,
  formStatus,
  onClose,
}: UserModalProps) => {
  const [, SetMsgState] = useRecoilState(MsgState);
  const { GetRoles, GetUserInfo } = AuthenService();
  const [Countrys, setCountry] = useState<Api.Country[]>([]);
  useEffect(() => {
    new Api.CountryApi().apiCountryGetAllCountrysGet().then((res) => {
      setCountry(res?.data.result || []);
    });
  }, []);
  const formikUser = useFormik({
    initialValues: userItem || defaultObj,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      let res: any | null = null;
      values = { ...values, userName: values.email };
      if (GetRoles().includes(Role.comadmin)) {
        values.companyId = GetUserInfo()?.user_info?.companyId || 0;
      }
      if (formStatus === "Add") {
        values.status = Api.RowStatus.Active;
        res = await UserApi.apiUserUserNomalCreatePost({ param: values });
      } else {
        res = await UserApi.apiUserEditPut({ param: values });
      }
      if (res && res.data.errorMessage) {
        SetMsgState((cur) => {
          return { ...cur, Msg: res?.data.errorMessage || "", show: true };
        });
      }
      if (res && res.data.isSuccess && onClose) {
        onClose("Save");
      }
    },
  });

  const getSelectedValue = (id: number | undefined) => {
    const item = Countrys.find((o) => o.id === id || 0)
    if (item) {
      return { label: item.niceName, id: item.id }
    }
    return null
  }
  return { formikUser, getSelectedValue, Countrys };
};

export interface ListUserQuery {
  keyword: string;
  companyId: number;
  page: number;
  limit: number;
}
const defaultQuery = {
  keyword: "",
  companyId: 0,
  page: 0,
  limit: 25,
};
export const listUsersNomalService = () => {
  const [anchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [, setPropsDialog] = useRecoilState(DialogState);
  const openMenu = Boolean(anchorEl);
  const handleShowMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    item: Api.Company
  ) => {
    setMenuAnchorEl(event.currentTarget);
    setRowSelect(item);
  };
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };
  const { GetRoles, GetUserInfo } = AuthenService();
  const [userModalProp, setCompanyModalProp] = useState<UserModalProps>({
    formStatus: "",
    userItem: null,
  } as UserModalProps);
  const [query, setQuery] = useState<Api.GetUserByCompanyParam>();
  const [rowSelected, setRowSelect] = useState<Api.User | null>(null);
  const [listData, setListData] =
    useState<Api.UserIPagedListOperationResult | null>(null);
  const [listCompanyData, setListCompany] = useState<Api.Company[]>([]);
  useEffect(() => {
    if (GetRoles().includes(Role.comadmin)) {
      defaultQuery.companyId = GetUserInfo()?.user_info?.companyId || 0;
    }
    setQuery(defaultQuery);
    getAllCompany();
  }, []);
  useEffect(() => {
    query && getUsers(query);
  }, [query]);
  const getUsers = async (param: Api.GetUserByCompanyParam) => {
    const data = await UserApi.apiUserGetUsersByCompanyPost({
      param: {
        ...param,
        userRole: [Role.comadmin, ""],
        page: (param.page || 0) + 1,
      },
    });
    setListData(data.data);
  };
  const getAllCompany = async () => {
    if (GetRoles().includes(Role.sysadmin)) {
      const data = await CompanyApi.apiCompanyGetAllCompanysGet(
        "",
        1,
        1000000
      );
      setListCompany(data?.data?.result?.items || []);
    } else {
      setListCompany([]);
    }
  };
  const onPageChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => {
    setQuery((cur) => {
      if (cur) return { ...cur, page: page };
    });
  };
  const onRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setQuery((cur) => {
      if (cur) return { ...cur, limit: parseInt(event.target.value) };
    });
  };
  const onKeyWordBlur = (value: string) => {
    if (query && value != query.keyWord) {
      setQuery((cur) => {
        if (cur) return { ...cur, keyWord: value };
      });
    }
  };
  const onChangeComapny = (companyId: number) => {
    if (query && companyId !== query.companyId) {
      setQuery((cur) => {
        if (cur) return { ...cur, companyId: companyId };
      });
    }
  };

  const AddNew = () => {
    setCompanyModalProp((cur) => {
      return { ...cur, formStatus: "Add", companyItem: null };
    });
  };
  const EditObj = () => {
    setCompanyModalProp((cur) => {
      return {
        ...cur,
        formStatus: "Edit",
        userItem: {
          id: rowSelected?.id,
          userName: rowSelected?.userName,
          firstName: rowSelected?.firstName,
          lastName: rowSelected?.lastName,
          phoneNumber: rowSelected?.phoneNumber,
          address: rowSelected?.address,
          email: rowSelected?.email,
          companyId: rowSelected?.companyId,
          countryId: rowSelected?.countryId,
          status: rowSelected?.status,
          password: "",
          confirmPassword: "",
        },
      };
    });
    handleMenuClose();
  };
  const SetRole = async () => {
    await UserApi.apiUserSetRolePut({
      param: { id: rowSelected?.id, role: "comadmin" },
    });
    query && getUsers(query);
    handleMenuClose();
  };
  const removeRole = async () => {
    await UserApi.apiUserRemoveUserRolePut({
      param: { id: rowSelected?.id, role: "comadmin" },
    });
    query && getUsers(query);
    handleMenuClose();
  };
  const updateStatus = async (rowStatus: Api.RowStatus) => {
    if (rowStatus === "Delete") {
      setPropsDialog({
        Msg: "Are you sure you want to delete this item?",
        show: true,
        DialogType: "Confirm",
        ActtionOK: () => {
          deleteItem(rowStatus);
        },
      });
    }
  };
  const deleteItem = async (rowStatus: Api.RowStatus) => {
    await UserApi.apiUserEditPut({
      param: {
        id: rowSelected?.id,
        userName: rowSelected?.userName,
        firstName: rowSelected?.firstName,
        lastName: rowSelected?.lastName,
        phoneNumber: rowSelected?.phoneNumber,
        address: rowSelected?.address,
        email: rowSelected?.email,
        companyId: rowSelected?.companyId,
        countryId: rowSelected?.countryId,
        status: rowStatus,
      },
    });
    query && getUsers(query);
    handleMenuClose();
  };
  const closeModal = () => {
    setCompanyModalProp((cur) => {
      return { ...cur, formStatus: "", companyItem: null };
    });
  };
  return {
    handleShowMenuClick,
    handleMenuClose,
    AddNew,
    EditObj,
    getUsers,
    listData,
    anchorEl,
    query,
    userModalProp,
    onPageChange,
    onRowsPerPageChange,
    onKeyWordBlur,
    openMenu,
    closeModal,
    updateStatus,
    onChangeComapny,
    listCompanyData,
    removeRole,
    SetRole,
    rowSelected,
  };
};
