import { LocalStorageKey } from "../common/constant/constant";
import * as Api from "../api/src/index";
// import { useNavigate } from "react-router-dom";
export const AuthenService = () => {
  //   const navigate = useNavigate();
  const GetUserInfo = (): Api.AccessToken | null => {
    const userInfoCache = localStorage.getItem(LocalStorageKey.UserInfo);
    let userinfo = null;
    if (userInfoCache) {
      userinfo = JSON.parse(userInfoCache) as Api.AccessToken;
    }
    return userinfo;
  };
  const GetRoles = (): string[] => {
    const userinfo = GetUserInfo();
    return userinfo?.user_info?.role || [];
  };
  const handleLogout = () => {
    // navigate("/login");
    window.location.href = "/login";
  };
  return { GetUserInfo, handleLogout, GetRoles };
};
