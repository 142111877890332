import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  TablePagination,
  TextField,
} from "@mui/material";
import { AddCircleOutline, MoreVert } from "@mui/icons-material";
import CompanyModal from "./modal/company";
import { listCompanyService } from "./service/companyService";
import * as Api from "../../../api/src/index";
import { AuthenService } from "../../../services/authenServices";
import { Role } from "../../../common/constant/constant";

type Order = "asc" | "desc";

interface HeadCell {
  disablePadding: boolean;
  id: keyof Api.Company;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Email",
  },
  {
    id: "phoneNumber",
    numeric: true,
    disablePadding: false,
    label: "Phone number",
  },
  {
    id: "country",
    numeric: false,
    disablePadding: false,
    label: "Country",
  },
  {
    id: "address",
    numeric: false,
    disablePadding: false,
    label: "Address",
  },
   {
    id: "licenseKey",
    numeric: false,
    disablePadding: false,
    label: "License key",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort?: (
    event: React.MouseEvent<unknown>,
    property: keyof Api.Company
  ) => void;
  order?: Order;
  orderBy?: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Api.Company) => (event: React.MouseEvent<unknown>) => {
      //onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell
          key={"action"}
          align={"center"}
          padding={"normal"}
          sortDirection={false}
        ></TableCell>
      </TableRow>
    </TableHead>
  );
}

export default function Companys() {
  const {
    handleShowMenuClick,
    handleMenuClose,
    AddNew,
    EditObj,
    getCompanys,
    listData,
    anchorEl,
    query,
    companyModalProp,
    onPageChange,
    onRowsPerPageChange,
    onKeyWordBlur,
    openMenu,
    closeModal,
    updateStatus,
  } = listCompanyService();
  const { GetRoles } = AuthenService();
  if (!GetRoles().includes(Role.sysadmin)) {
    return (<>
      <h2 style={{ textAlign: 'center' }}>
        User does not permission access
      </h2>
    </>)
  }
  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <Box
          display="grid"
          gap={2}
          style={{ padding: "10px 10px", display: "flex" }}
        >
          <Box>
            <TextField
              size="small"
              label="Key word"
              onBlur={(e) => {
                onKeyWordBlur(e.target.value);
              }}
            />
          </Box>
          <Box flex={1} textAlign="right">
            <Button
              variant="outlined"
              onClick={() => {
                AddNew();
              }}
              startIcon={<AddCircleOutline />}
            >
              Add
            </Button>
          </Box>
        </Box>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"small"}
          >
            <EnhancedTableHead
              rowCount={listData?.result?.items?.length || 0}
              numSelected={0}
            />
            <TableBody>
              {listData?.result?.items?.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={row.id}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell component="th" id={labelId} scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="left">{row.email}</TableCell>
                    <TableCell align="right">{row.phoneNumber}</TableCell>
                    <TableCell align="left">{row.country?.niceName}</TableCell>
                    <TableCell align="left">{row.address}</TableCell>
                    <TableCell align="left">{row.licenseKey}</TableCell>
                    <TableCell align="right">
                      {row.status === Api.RowStatus.Active
                        ? "Active"
                        : "InActive"}
                    </TableCell>
                    <TableCell align="center" width={30}>
                      <IconButton
                        id="basic-button"
                        aria-controls={openMenu ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={openMenu ? "true" : undefined}
                        onClick={(e) => {
                          handleShowMenuClick(e, row);
                        }}
                      >
                        <MoreVert />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleMenuClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                EditObj();
              }}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                updateStatus(Api.RowStatus.Delete);
              }}
            >
              Delete
            </MenuItem>
          </Menu>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={listData?.result?.total || 0}
          rowsPerPage={query?.limit || 0}
          page={query?.page || 0}
          onPageChange={onPageChange}
          onRowsPerPageChange={(e) => {
            onRowsPerPageChange(e);
          }}
        />
      </Paper>
      {companyModalProp.formStatus && (
        <CompanyModal
          companyItem={companyModalProp.companyItem}
          formStatus={companyModalProp.formStatus}
          onClose={(action) => {
            if (action === "Save" && query) getCompanys(query);
            closeModal();
          }}
        ></CompanyModal>
      )}
    </Box>
  );
}
